import { observer } from 'mobx-react';
import dynamic from 'next/dynamic';
import { compose, translator } from 'next-ssr-middleware';

import { PageHead } from '../components/PageHead';
import { i18n } from '../models/Translation';
import styles from '../styles/Home.module.less';

export const getServerSideProps = compose(translator(i18n));

const DashboardMain = dynamic(() => import('../components/DashboardMain'), {
  ssr: false,
});

const HomePage = observer(() => (
  <main className={styles.main}>
    <PageHead />
    <DashboardMain />
  </main>
));

export default HomePage;
