import Head from 'next/head';
import type { FC } from 'react';

import { SITE_NAME, SITE_SUMMARY } from '../models/Env';

export interface PageHeadProps {
  title?: string;
  description?: string;
}

export const PageHead: FC<PageHeadProps> = ({
  title,
  description = SITE_SUMMARY,
  children,
}) => (
  <Head>
    <title>
      {title}
      {title && ' - '}
      {SITE_NAME}
    </title>

    {description && <meta name="description" content={description} />}

    {children}
  </Head>
);
